<template>
  <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="17" cy="17" r="17" fill="#F4F4F4"/>
    <path d="M15.9074 5.25191C14.3612 5.47516 12.9935 6.12168 11.8703 7.1682C9.86343 9.03799 9.02686 11.8613 9.70364 14.466C9.92453 15.3171 10.1548 15.8008 10.8269 16.8334C14.1873 21.987 16.5184 25.4986 16.6265 25.5777C16.8004 25.7033 17.0823 25.7033 17.2562 25.5777C17.3643 25.4986 19.6954 21.987 23.0558 16.8334C23.7279 15.8008 23.9582 15.3125 24.1791 14.466C24.6772 12.531 24.353 10.4147 23.2955 8.73101C22.1534 6.91239 20.4474 5.73098 18.3701 5.32632C17.7121 5.19609 16.5466 5.16353 15.9074 5.25191ZM18.2902 7.02402C18.7132 7.1496 19.0751 7.49379 19.2443 7.931C19.2866 8.04263 19.3148 8.43333 19.3289 9.17287L19.3477 10.2566H19.921C20.7999 10.2566 21.1195 10.3868 21.378 10.8613C21.4861 11.0566 21.5096 11.1589 21.5096 11.3868C21.5002 11.8985 20.9503 16.6892 20.8798 16.8613C20.7811 17.0939 20.5837 17.3078 20.3299 17.4567L20.1137 17.5823L17.04 17.5962C13.6515 17.6102 13.7126 17.6148 13.3554 17.3218C12.9794 17.0148 12.9747 16.9962 12.6598 14.1729C12.5047 12.7729 12.3731 11.5078 12.3731 11.3636C12.3731 11.1636 12.4013 11.0427 12.5047 10.8659C12.7632 10.3915 13.0875 10.2566 13.9617 10.2566H14.535L14.5538 9.17287C14.5773 8.01937 14.5914 7.9217 14.8358 7.57286C15.0097 7.31704 15.2447 7.14495 15.5455 7.04262C15.7523 6.9682 15.9591 6.9589 16.9225 6.95425C17.792 6.95425 18.1069 6.9682 18.2902 7.02402Z" :fill="color || '#F5831F'"/>
    <path d="M15.9821 8.38654C15.9633 8.40514 15.9492 8.8377 15.9492 9.34469V10.2656H16.9362H17.9231L17.9137 9.32143L17.8996 8.38189L16.955 8.36793C16.438 8.36328 16.0009 8.37258 15.9821 8.38654Z" :fill="color || '#F5831F'"/>
    <path d="M13.8094 11.8327C13.8235 11.9303 13.9316 12.8792 14.0491 13.9397C14.1666 15.0001 14.2747 15.949 14.2888 16.042L14.317 16.2188H16.9395H19.5667L19.5949 16.0653C19.609 15.9862 19.7124 15.0373 19.8299 13.9629C19.9474 12.8885 20.0555 11.9303 20.0696 11.8327L20.0978 11.6606H16.9395H13.7812L13.8094 11.8327Z" :fill="color || '#F5831F'"/>
    <path d="M13.6429 25.4268C11.5092 25.7059 9.78438 26.2501 9.18281 26.8269C8.93372 27.0641 8.93842 27.1804 9.20161 27.4176C9.85958 28.0176 11.5985 28.5478 13.7886 28.8176C18.0842 29.3432 23.2399 28.6873 24.6499 27.4362C24.9319 27.185 24.946 27.0734 24.7063 26.8455C24.0718 26.2362 22.3752 25.7013 20.1992 25.4268C19.0994 25.2873 19.231 25.2408 18.8033 25.892C18.5965 26.2036 18.3474 26.5199 18.2393 26.6083C17.4498 27.2408 16.4158 27.2408 15.6356 26.6083C15.5276 26.5199 15.2738 26.1989 15.0717 25.892L14.7051 25.3338L14.4936 25.3385C14.3761 25.3431 13.9954 25.3803 13.6429 25.4268Z" :fill="color || '#F5831F'"/>
  </svg>
</template>

<script>
export default {
  name: 'WarehousePin',
  props: ['color'],
}
</script>

<style>

</style>
