<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="57" height="57" viewBox="0 0 57 57" fill="none">
    <circle
      v-if="active"
      cx="28.5"
      cy="28.5"
      r="28.5"
      fill="rgba(202, 82, 86, 0.2)"
    />
    <circle v-else cx="28.5" cy="28.5" r="28.5" fill="#D2D2D2" />
    <path
      d="M41.3567 45.1067H17.3633C16.7743 45.1067 16.2969 44.6293 16.2969 44.0341V21.5101C16.2969 20.9211 16.7743 20.4375 17.3633 20.4375H41.3567C41.9456 20.4375 42.423 20.9149 42.423 21.5101V44.0341C42.423 44.6293 41.9456 45.1067 41.3567 45.1067ZM18.4358 42.9678H40.2903V22.5826H18.4358V42.9678Z"
      :fill="active ? 'rgba(202, 82, 86, 1)' : 'black'" />
    <path
      d="M41.3577 22.5859C40.9485 22.5859 40.5579 22.3503 40.3843 21.9597L37.2906 15.1275H29.3672V12.9886H37.8114L43.4843 11.0914C43.974 10.924 44.5196 11.141 44.7676 11.5998L48.7603 19.021C48.9091 19.3 48.9277 19.6348 48.8099 19.9324C48.6859 20.23 48.4441 20.4532 48.1341 20.5462L41.6863 22.5425C41.5685 22.5673 41.4631 22.5859 41.3577 22.5859ZM39.4419 14.6873L41.9467 20.2114L46.2556 18.8784L43.3045 13.3915L39.4419 14.6873Z"
      :fill="active ? 'rgba(202, 82, 86, 1)' : 'black'" />
    <path
      d="M17.365 22.5818C17.2596 22.5818 17.1542 22.5694 17.0488 22.5322L10.6009 20.5358C10.2971 20.4428 10.0491 20.2134 9.92513 19.9221C9.80114 19.6245 9.81974 19.2897 9.97473 19.0107L13.9674 11.5895C14.2154 11.1307 14.7548 10.9199 15.2508 11.0811L20.9237 12.9782H29.3617V15.1172H21.4383L18.3445 21.9494C18.1647 22.3462 17.7804 22.5818 17.365 22.5818ZM12.4795 18.8805L16.7884 20.2134L19.2931 14.6894L15.4368 13.3936L12.4795 18.8805Z"
      :fill="active ? 'rgba(202, 82, 86, 1)' : 'black'" />
    <path
      d="M18.4242 24.1018C19.6828 23.3578 21.301 23.9468 21.7846 25.3232C22.0636 26.1229 22.7579 26.6995 23.5949 26.8421C25.0333 27.0777 25.9013 28.5719 25.3867 29.942C25.0891 30.7356 25.2441 31.6222 25.7959 32.267C26.7444 33.3768 26.4468 35.0755 25.1759 35.7885C24.4381 36.2039 23.9855 36.9851 23.9979 37.8345C24.0165 39.2914 22.6897 40.4012 21.2576 40.1346C20.4268 39.9796 19.5774 40.2896 19.038 40.9406C18.1142 42.069 16.3845 42.069 15.4607 40.9406C14.9275 40.2896 14.0719 39.9796 13.2412 40.1346C11.809 40.4074 10.4822 39.2976 10.5008 37.8345C10.5132 36.9913 10.0606 36.2039 9.32285 35.7885C8.05188 35.0755 7.74809 33.3706 8.70287 32.267C9.25465 31.6284 9.40965 30.7356 9.11206 29.942C8.59747 28.5781 9.45925 27.0777 10.9038 26.8421C11.7408 26.7057 12.429 26.1229 12.708 25.3232C13.1916 23.9468 14.8159 23.3578 16.0683 24.1018C16.7937 24.5358 17.6989 24.5358 18.4242 24.1018Z"
      fill="url(#paint0_linear_6103_69699)" />
    <path
      d="M17.0473 38.6082C17.0411 38.6082 17.0287 38.6082 17.0225 38.6082C15.5469 38.5524 14.1644 37.951 13.1228 36.9032C10.9653 34.7457 10.8475 31.2489 12.8562 28.955C13.0794 28.6946 13.4762 28.6698 13.7304 28.8992C13.9908 29.1224 14.0156 29.5192 13.7862 29.7734C12.2052 31.5837 12.2982 34.3303 13.997 36.029C14.8154 36.8474 15.9065 37.3248 17.0659 37.3682C17.4069 37.3806 17.6735 37.6658 17.6611 38.013C17.6549 38.3478 17.3821 38.6082 17.0473 38.6082Z"
      :fill="active ? 'rgba(202, 82, 86, 1)' : 'black'" />
    <path d="M14.7653 27.7188L13.6804 30.8683L13.2154 29.4857L11.7832 29.2067L14.7653 27.7188Z" :fill="active ? 'rgba(202, 82, 86, 1)' : 'black'" />
    <path
      d="M20.9234 37.0904C20.7622 37.0904 20.6072 37.0284 20.4832 36.9106C20.2414 36.6688 20.2414 36.2782 20.4832 36.0364C22.2688 34.2509 22.2688 31.3431 20.4832 29.5514C19.5842 28.6524 18.3504 28.1688 17.0671 28.2122C16.7199 28.2246 16.4347 27.958 16.4223 27.617C16.4099 27.276 16.6765 26.9846 17.0175 26.9722C18.6295 26.9164 20.2104 27.5302 21.3574 28.6772C23.6265 30.9464 23.6265 34.6415 21.3574 36.9106C21.2458 37.0284 21.0846 37.0904 20.9234 37.0904Z"
      :fill="active ? 'rgba(202, 82, 86, 1)' : 'black'" />
    <path d="M19.3789 38.0204L20.6685 34.9453L21.0405 36.3527L22.4478 36.7309L19.3789 38.0204Z" :fill="active ? 'rgba(202, 82, 86, 1)' : 'black'" />
    <path
      d="M37.6 41.1424H25.5165C25.1755 41.1424 24.8965 40.8634 24.8965 40.5224C24.8965 40.1814 25.1755 39.9024 25.5165 39.9024H36.98V25.37C36.98 25.029 37.259 24.75 37.6 24.75C37.941 24.75 38.22 25.029 38.22 25.37V40.5224C38.22 40.8696 37.941 41.1424 37.6 41.1424Z"
      :fill="active ? 'rgba(202, 82, 86, 1)' : 'black'" />
    <defs>
      <linearGradient id="paint0_linear_6103_69699" x1="8.13868" y1="32.783" x2="26.3514" y2="32.783"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#F26522" />
        <stop offset="0.2497" stop-color="#F47D23" />
        <stop offset="1" stop-color="#F47920" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'ProcessIcon',
  props: ['active'],
}
</script>

<style>
</style>
