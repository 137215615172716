<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="57"
    height="57"
    viewBox="0 0 57 57"
    fill="none"
  >
    <circle
      v-if="active"
      cx="28.5"
      cy="28.5"
      r="28.5"
      fill="rgba(202, 82, 86, 0.2)"
    />
    <circle v-else cx="28.5" cy="28.5" r="28.5" fill="#D2D2D2" />
    <path
      d="M37.5807 46.4859H19.5144C16.9832 46.4859 14.9277 44.4304 14.9277 41.8992V15.489C14.9277 12.9639 16.9832 10.9023 19.5144 10.9023H37.5807C40.1058 10.9023 42.1674 12.9578 42.1674 15.489V41.8992C42.1674 44.4304 40.1119 46.4859 37.5807 46.4859ZM19.5144 13.0127C18.1482 13.0127 17.032 14.1228 17.032 15.4951V41.9053C17.032 43.2716 18.1482 44.3877 19.5144 44.3877H37.5807C38.9469 44.3877 40.0631 43.2776 40.0631 41.9053V15.4951C40.0631 14.1289 38.9469 13.0127 37.5807 13.0127H19.5144Z"
      :fill="active ? 'rgba(202, 82, 86, 1)' : 'black'"
    />
    <path
      d="M34.4394 14.4222H22.6555C21.8137 14.4222 21.1367 13.739 21.1367 12.9034V11.0187C21.1367 10.177 21.8198 9.5 22.6555 9.5H34.4394C35.2811 9.5 35.9581 10.1831 35.9581 11.0187V12.9034C35.9581 13.739 35.2811 14.4222 34.4394 14.4222Z"
      :fill="active ? 'rgba(202, 82, 86, 1)' : 'black'"
    />
    <path
      d="M36.7391 18.4035H20.3502C20.0147 18.4035 19.7402 18.129 19.7402 17.7935C19.7402 17.4581 20.0147 17.1836 20.3502 17.1836H36.7391C37.0746 17.1836 37.349 17.4581 37.349 17.7935C37.349 18.1351 37.0807 18.4035 36.7391 18.4035Z"
      :fill="active ? 'rgba(202, 82, 86, 1)' : 'black'"
    />
    <path
      d="M36.7391 22.8839H20.3502C20.0147 22.8839 19.7402 22.6095 19.7402 22.274C19.7402 21.9385 20.0147 21.6641 20.3502 21.6641H36.7391C37.0746 21.6641 37.349 21.9385 37.349 22.274C37.349 22.6095 37.0807 22.8839 36.7391 22.8839Z"
      :fill="active ? 'rgba(202, 82, 86, 1)' : 'black'"
    />
    <path
      d="M29.8346 27.3605H20.3502C20.0147 27.3605 19.7402 27.086 19.7402 26.7506C19.7402 26.4151 20.0147 26.1406 20.3502 26.1406H29.8285C30.164 26.1406 30.4385 26.4151 30.4385 26.7506C30.4446 27.086 30.1701 27.3605 29.8346 27.3605Z"
      :fill="active ? 'rgba(202, 82, 86, 1)' : 'black'"
    />
    <path
      d="M41.2654 25.1043C42.5035 24.3724 44.0955 24.9519 44.5712 26.3059C44.8457 27.0927 45.5288 27.66 46.3522 27.8002C47.7673 28.032 48.6212 29.502 48.1149 30.8499C47.8222 31.6306 47.9746 32.5028 48.5175 33.1372C49.4507 34.229 49.1579 35.9002 47.9075 36.6016C47.1817 37.0103 46.7365 37.7788 46.7487 38.6144C46.767 40.0477 45.4617 41.1395 44.0528 40.8772C43.2354 40.7248 42.3998 41.0297 41.8692 41.6702C40.9604 42.7802 39.2587 42.7802 38.3499 41.6702C37.8192 41.0297 36.9836 40.7248 36.1663 40.8772C34.7574 41.1456 33.4521 40.0477 33.4704 38.6144C33.4826 37.7849 33.0374 37.0103 32.3115 36.6016C31.0612 35.9002 30.7623 34.2229 31.7016 33.1372C32.2444 32.5089 32.3969 31.6306 32.1041 30.8499C31.5979 29.5081 32.4457 28.032 33.8669 27.8002C34.6903 27.6661 35.3673 27.0927 35.6479 26.3059C36.1236 24.9519 37.7216 24.3724 38.9537 25.1043C39.6551 25.5313 40.5456 25.5313 41.2654 25.1043Z"
      :fill="active ? 'rgba(202, 82, 86, 1)' : 'black'"
    />
    <path
      d="M38.2519 38.0136C38.0933 38.0136 37.9408 37.9526 37.8189 37.8367L34.0007 34.0246C33.7628 33.7867 33.7628 33.4025 34.0007 33.1646C34.2385 32.9267 34.6228 32.9267 34.8607 33.1646L38.2885 36.5863L44.9795 31.1152C45.2418 30.9017 45.626 30.9383 45.8395 31.2006C46.053 31.4629 46.0164 31.8471 45.7541 32.0606L38.6362 37.8794C38.5264 37.9709 38.3861 38.0136 38.2519 38.0136Z"
      :fill="active ? 'white' : 'black'"
    />
    <path
      d="M22.9237 34.0901H20.6547C20.3985 34.0901 20.1973 33.8827 20.1973 33.6327V31.3637C20.1973 31.1075 20.4046 30.9062 20.6547 30.9062H22.9237C23.1798 30.9062 23.3811 31.1136 23.3811 31.3637V33.6327C23.3872 33.8827 23.1798 34.0901 22.9237 34.0901Z"
      :fill="active ? 'rgba(202, 82, 86, 1)' : 'black'"
    />
    <path
      d="M22.9237 40.594H20.6547C20.3985 40.594 20.1973 40.3866 20.1973 40.1366V37.8676C20.1973 37.6114 20.4046 37.4102 20.6547 37.4102H22.9237C23.1798 37.4102 23.3811 37.6175 23.3811 37.8676V40.1366C23.3872 40.3866 23.1798 40.594 22.9237 40.594Z"
      :fill="active ? 'rgba(202, 82, 86, 1)' : 'black'"
    />
    <defs>
      <linearGradient
        id="paint0_linear_6103_69697"
        x1="31.1436"
        y1="33.6429"
        x2="49.0611"
        y2="33.6429"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F26522" />
        <stop offset="0.2497" stop-color="#F47D23" />
        <stop offset="1" stop-color="#F47920" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'orderIcon',
  props: ['active', 'color'],
}
</script>

<style></style>
