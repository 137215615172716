<template>
  <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      v-if="active"
      cx="28.5"
      cy="28.5"
      r="28.5"
      fill="rgba(202, 82, 86, 0.2)"
    />
    <circle v-else cx="28.5" cy="28.5" r="28.5" fill="#D2D2D2" />
    <path d="M36.9983 44.6732H20.4791C18.1702 44.6732 16.2852 42.7937 16.2852 40.4793V16.3307C16.2852 14.0218 18.1646 12.1367 20.4791 12.1367H36.9983C39.3072 12.1367 41.1922 14.0162 41.1922 16.3307V40.4793C41.1922 42.7937 39.3128 44.6732 36.9983 44.6732ZM20.4791 14.0664C19.2298 14.0664 18.2092 15.0814 18.2092 16.3362V40.4848C18.2092 41.7341 19.2298 42.7547 20.4791 42.7547H36.9983C38.2476 42.7547 39.2682 41.7397 39.2682 40.4848V16.3362C39.2682 15.087 38.2476 14.0664 36.9983 14.0664H20.4791Z" :fill="active ? 'rgba(202, 82, 86, 1)' : 'black'" />
    <path d="M34.1245 15.3561H23.3496C22.58 15.3561 21.9609 14.7315 21.9609 13.9675V12.2442C21.9609 11.4745 22.5856 10.8555 23.3496 10.8555H34.1245C34.8941 10.8555 35.5131 11.4801 35.5131 12.2442V13.9675C35.5187 14.7315 34.8941 15.3561 34.1245 15.3561Z" :fill="active ? 'rgba(202, 82, 86, 1)' : 'black'" />
    <path d="M36.2346 18.9943H21.2491C20.9424 18.9943 20.6914 18.7433 20.6914 18.4366C20.6914 18.1299 20.9424 17.8789 21.2491 17.8789H36.2346C36.5414 17.8789 36.7923 18.1299 36.7923 18.4366C36.7923 18.7489 36.5414 18.9943 36.2346 18.9943Z" :fill="active ? 'rgba(202, 82, 86, 1)' : 'black'" />
    <path d="M36.2346 23.092H21.2491C20.9424 23.092 20.6914 22.841 20.6914 22.5343C20.6914 22.2275 20.9424 21.9766 21.2491 21.9766H36.2346C36.5414 21.9766 36.7923 22.2275 36.7923 22.5343C36.7923 22.841 36.5414 23.092 36.2346 23.092Z" :fill="active ? 'rgba(202, 82, 86, 1)' : 'black'" />
    <path d="M29.9158 27.1857H21.2491C20.9424 27.1857 20.6914 26.9348 20.6914 26.628C20.6914 26.3213 20.9424 26.0703 21.2491 26.0703H29.9158C30.2226 26.0703 30.4735 26.3213 30.4735 26.628C30.4735 26.9348 30.2226 27.1857 29.9158 27.1857Z" :fill="active ? 'rgba(202, 82, 86, 1)' : 'black'" />
    <path d="M40.366 25.12C41.4981 24.4507 42.9538 24.9805 43.3888 26.2187C43.6397 26.9381 44.2644 27.4568 45.0173 27.585C46.3111 27.797 47.0919 29.141 46.629 30.3735C46.3613 31.0874 46.5007 31.8849 46.9971 32.4649C47.8504 33.4632 47.5827 34.9913 46.4394 35.6327C45.7757 36.0064 45.3686 36.7091 45.3797 37.4731C45.3965 38.7837 44.203 39.782 42.9147 39.5422C42.1674 39.4028 41.4033 39.6816 40.9181 40.2672C40.0871 41.2822 38.5312 41.2822 37.7002 40.2672C37.2206 39.6816 36.4509 39.4028 35.7036 39.5422C34.4153 39.7876 33.2218 38.7837 33.2385 37.4731C33.2497 36.7146 32.8426 36.0064 32.1789 35.6327C31.0356 34.9913 30.7623 33.4577 31.6212 32.4649C32.1175 31.8905 32.257 31.0874 31.9893 30.3735C31.5264 29.1466 32.3016 27.797 33.601 27.585C34.3539 27.4623 34.973 26.9381 35.2296 26.2187C35.6646 24.9805 37.1257 24.4507 38.2523 25.12C38.8992 25.5104 39.7135 25.5104 40.366 25.12Z" :fill="active ? 'rgba(202, 82, 86, 1)' : 'black'"/>
    <path d="M23.6023 33.3409H21.5277C21.2934 33.3409 21.1094 33.1513 21.1094 32.9226V30.848C21.1094 30.6137 21.299 30.4297 21.5277 30.4297H23.6023C23.8365 30.4297 24.0206 30.6193 24.0206 30.848V32.9226C24.0206 33.1513 23.831 33.3409 23.6023 33.3409Z" :fill="active ? 'rgba(202, 82, 86, 1)' : 'black'" />
    <path d="M23.6023 39.2862H21.5277C21.2934 39.2862 21.1094 39.0966 21.1094 38.8679V36.7933C21.1094 36.559 21.299 36.375 21.5277 36.375H23.6023C23.8365 36.375 24.0206 36.5646 24.0206 36.7933V38.8679C24.0206 39.0966 23.831 39.2862 23.6023 39.2862Z" :fill="active ? 'rgba(202, 82, 86, 1)' : 'black'" />
    <path d="M35.3716 37.4649C35.2266 37.4649 35.0872 37.4091 34.9756 37.3031C34.7581 37.0856 34.7581 36.7343 34.9756 36.5168L42.7277 28.7647C42.9452 28.5472 43.2966 28.5472 43.5141 28.7647C43.7316 28.9822 43.7316 29.3336 43.5141 29.5511L35.762 37.3031C35.656 37.4091 35.5166 37.4649 35.3716 37.4649Z" :fill="active ? 'white' : 'black'" />
    <path d="M43.1237 37.4649C42.9787 37.4649 42.8393 37.4091 42.7277 37.3031L34.9756 29.5511C34.7581 29.3336 34.7581 28.9822 34.9756 28.7647C35.1931 28.5472 35.5445 28.5472 35.762 28.7647L43.5141 36.5168C43.7316 36.7343 43.7316 37.0856 43.5141 37.3031C43.4081 37.4091 43.2687 37.4649 43.1237 37.4649Z" :fill="active ? 'white' : 'black'" />
  </svg>
</template>

<script>
export default {
  name: 'OrderCancel',
  props: ['active'],
}
</script>

<style>
</style>
