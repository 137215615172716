<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="57" height="57" viewBox="0 0 57 57" fill="none">
    <circle
      v-if="active"
      cx="28.5"
      cy="28.5"
      r="28.5"
      fill="rgba(202, 82, 86, 0.2)"
    />
    <circle v-else cx="28.5" cy="28.5" r="28.5" fill="#D2D2D2" />
    <path
      d="M41.44 44.5412H17.2976C16.705 44.5412 16.2246 44.0608 16.2246 43.4619V20.798C16.2246 20.2053 16.705 19.7188 17.2976 19.7188H41.44C42.0327 19.7188 42.513 20.1991 42.513 20.798V43.4619C42.5193 44.0608 42.0389 44.5412 41.44 44.5412ZM18.3768 42.3889H40.367V21.8772H18.3768V42.3889Z"
      :fill="active ? 'rgba(202, 82, 86, 1)' : 'black'" />
    <path
      d="M40.4586 21.248L37.3457 14.3671H29.373V12.2148H38.0444C38.4686 12.2148 38.8491 12.4644 39.0238 12.8449L42.4237 20.3559L40.4586 21.248Z"
      :fill="active ? 'rgba(202, 82, 86, 1)' : 'black'" />
    <path
      d="M18.2831 21.2496L16.3242 20.3637L19.7241 12.8527C19.8988 12.466 20.2793 12.2227 20.7036 12.2227H29.3749V14.3749H21.4022L18.2831 21.2496Z"
      :fill="active ? 'rgba(202, 82, 86, 1)' : 'black'" />
    <path
      d="M37.659 40.5489H25.5066C25.1635 40.5489 24.8828 40.2682 24.8828 39.9251C24.8828 39.5819 25.1635 39.3012 25.5066 39.3012H37.0414V24.6785C37.0414 24.3354 37.3221 24.0547 37.6652 24.0547C38.0083 24.0547 38.289 24.3354 38.289 24.6785V39.9251C38.2828 40.2744 38.0083 40.5489 37.659 40.5489Z"
      :fill="active ? 'rgba(202, 82, 86, 1)' : 'black'" />
    <path
      d="M33.148 20.0031L32.0251 13.2969H29.7481H29.6108H27.3339L26.2109 20.0031L26.2297 25.2932C26.2297 25.3307 26.2421 25.3681 26.2671 25.3993L26.866 26.1167C26.9284 26.1978 27.0531 26.1978 27.1155 26.1167L27.5397 25.6114C27.6083 25.5303 27.7269 25.5303 27.7892 25.6114L28.2135 26.1167C28.2821 26.1978 28.4006 26.1978 28.463 26.1167L28.8872 25.6114C28.9496 25.5303 29.0743 25.5303 29.1367 25.6114L29.5609 26.1167C29.6233 26.1978 29.7481 26.1978 29.8105 26.1167L30.2347 25.6114C30.3033 25.5303 30.4218 25.5303 30.4842 25.6114L30.9084 26.1167C30.9708 26.1978 31.0956 26.1978 31.1579 26.1167L31.5822 25.6114C31.6508 25.5303 31.7693 25.5303 31.8317 25.6114L32.2559 26.1167C32.3245 26.1978 32.443 26.1978 32.5054 26.1167L33.1043 25.3993C33.1293 25.3681 33.1418 25.3307 33.1418 25.2932V20.0031H33.148Z"
      :fill="active ? 'rgba(202, 82, 86, 1)' : 'black'" />
    <path
      d="M18.4881 23.4007C19.7545 22.6521 21.3827 23.2447 21.8693 24.6296C22.15 25.4344 22.8487 26.0145 23.6909 26.158C25.1382 26.3951 26.0115 27.8985 25.4938 29.2772C25.1943 30.0757 25.3503 30.9678 25.9055 31.6166C26.86 32.7332 26.5605 34.4426 25.2817 35.16C24.5393 35.5779 24.0839 36.364 24.0964 37.2186C24.1151 38.6846 22.7801 39.8013 21.339 39.5331C20.5031 39.3771 19.6484 39.689 19.1057 40.344C18.1762 41.4794 16.4357 41.4794 15.5061 40.344C14.9634 39.689 14.1088 39.3771 13.2728 39.5331C11.8318 39.8075 10.4967 38.6909 10.5155 37.2186C10.5279 36.3702 10.0725 35.5779 9.33018 35.16C8.05132 34.4426 7.74564 32.727 8.70635 31.6166C9.26156 30.974 9.41752 30.0757 9.11808 29.2772C8.6003 27.9048 9.46744 26.3951 10.921 26.158C11.7631 26.0208 12.4556 25.4344 12.7426 24.6296C13.2292 23.2447 14.8636 22.6521 16.1238 23.4007C16.8474 23.8311 17.7582 23.8311 18.4881 23.4007Z"
      fill="url(#paint0_linear_6103_69700)" />
    <path
      d="M15.4056 36.6036C15.2434 36.6036 15.0874 36.5412 14.9627 36.4227L11.0575 32.5237C10.8142 32.2804 10.8142 31.8874 11.0575 31.6441C11.3008 31.4008 11.6938 31.4008 11.9371 31.6441L15.443 35.1438L22.2865 29.548C22.5548 29.3297 22.9478 29.3671 23.1661 29.6354C23.3844 29.9036 23.347 30.2967 23.0788 30.515L15.7986 36.4664C15.6863 36.56 15.5491 36.6036 15.4056 36.6036Z"
      :fill="active ? 'rgba(202, 82, 86, 1)' : 'black'" />
    <defs>
      <linearGradient id="paint0_linear_6103_69700" x1="8.13685" y1="32.1337" x2="26.4629" y2="32.1337"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#F26522" />
        <stop offset="0.2497" stop-color="#F47D23" />
        <stop offset="1" stop-color="#F47920" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'BoxIcon',
  props: ['active'],
}
</script>

<style>
</style>
